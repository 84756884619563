<template>
  <div class="">
    <el-card class="box-card">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <!-- <el-form-item label="公司简称:" prop="name" >
					<el-input v-model="ruleForm.name" placeholder="请输入公司简称" style="width: 500px;"></el-input>
				</el-form-item> -->
        <el-form-item label="新手机号:" prop="phone">
          <el-input
            v-model="ruleForm.phone"
            placeholder="请输入新手机号"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="短信验证码:" prop="code">
          <div class="verify-wrapper">
            <el-input
              type="text"
              maxlength="6"
              placeholder="请输入验证码"
              v-model="ruleForm.code"
              style="width: 320px"
            ></el-input>
            <el-button
              class="btn-orange"
              :disabled="disabled"
              @click="getCode"
              >{{ valiBtn }}</el-button
            >
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        name: "",
        phone: "",
        code: "",
      },
      rules: {
        name: [{ required: true, message: "请输入公司简称", trigger: "blur" }],
        phone: [{ required: true, message: "请输入新手机号", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      valiBtn: "获取验证码",
      disabled: false,
    };
  },
  created() {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let config = {
            phone: this.ruleForm.phone,
            code: this.ruleForm.code,
          };
          this.$api.updatePhone("post", config).then((res) => {
            window.sessionStorage.setItem("userInfo", JSON.stringify(res.data));
            this.$message({
              message: "手机号修改成功，请重新登录",
              type: "success",
            });
            this.$api.outlogin("post").then(() => {
              window.sessionStorage.clear;

              this.$router.push("/login");
              this.$IM.outLogin();
            });
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //获取验证码 并只验证手机号 是否正确
    getCode() {
      this.$refs["ruleForm"].validateField("phone", (err) => {
        if (err) {
          return;
        } else {
          this.tackBtn(); //验证码倒数60秒
          let fd = {
            phone: this.ruleForm.phone,
            type: 0,
          };
          this.$api.phonemsg("post", fd).then(() => {});
        }
      });
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.valiBtn = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.valiBtn = time + "秒后重发";
          time--;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-card {
  border-radius: 0px;
  border: none;
  box-shadow: 0px 4px 13px 0px rgb(0 0 0 / 3%) !important;
}
/deep/.el-card__body {
  padding: 80px 30px 160px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.demo-ruleForm {
  width: 700px;
  /deep/ .el-input__inner {
    border-radius: 0px;
  }
  /deep/ .el-button {
    border-radius: 0px;
    width: 500px;
    margin-top: 50px;
    background: #00bcff;
    border: none;
  }
}

.verify-wrapper {
  display: flex;
  align-items: center;
  /deep/ .el-button {
    margin-top: 0px;
    width: 150px;
    padding: 13px 20px;
    background-color: #00bcff;
    border: none;
    color: #ffffff;
    margin-left: 30px;
  }
}
</style>
